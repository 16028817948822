import { MainLayout } from '@common/components';

import * as S from './404View.styles';

const notfoundImage = '/images/404.svg';

const NotFoundView = () => {
  return (
    <MainLayout withoutHeader>
      <S.Container>
        <S.NotFoundImage src={notfoundImage} alt="" />
        <S.Tip>ocorreu um erro</S.Tip>
        <S.Title as="h1">Página não encontrada</S.Title>
        <S.Subtitle as="h2">
          Por algum motivo a página que você estava acessando não está mais disponível.
        </S.Subtitle>
        <S.BackToLink href="/">Voltar ao início</S.BackToLink>
      </S.Container>
    </MainLayout>
  );
};

export { NotFoundView };
