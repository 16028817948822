import { useAuth } from '@contexts/AuthUserContext';

import { Header } from '@common/components/Header';

import { MainContainer } from './MainLayout.styles';

export type MainLayoutProps = {
  children?: JSX.Element | JSX.Element[];
  withoutHeader?: boolean;
};

const MainLayout: React.FC<MainLayoutProps> = ({ children, withoutHeader }) => {
  const { authUser, signOut } = useAuth();

  const handleSignOut = async () => {
    await signOut();
  };
  return (
    <MainContainer>
      {!withoutHeader && <Header username={authUser?.name} onLogoutClick={handleSignOut} />}
      {children}
    </MainContainer>
  );
};

export { MainLayout };
