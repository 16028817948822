import { Ref, forwardRef } from 'react';

import NextLink, { LinkProps } from 'next/link';

import { Link as UnitedLink } from '@olist/united';

import * as S from './Link.style';

type LinkRef = HTMLAnchorElement;
export type NextLinkProps = LinkProps & {
  children: React.ReactNode;
  variation?: 'alternate' | 'default';
};

const UnitedCustomLink = (
  { href, variation = 'default', ...props }: NextLinkProps,
  ref: Ref<LinkRef>
) => {
  return (
    <NextLink href={href} passHref>
      <S.Link ref={ref}>
        <UnitedLink as="span" variation={variation} {...props} />
      </S.Link>
    </NextLink>
  );
};

const Link = forwardRef<LinkRef, NextLinkProps>(UnitedCustomLink);

export { Link };
