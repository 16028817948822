import { themeGet } from '@styled-system/theme-get';
import styled, { css } from 'styled-components';

import { Flex, Text as UnitedText } from '@olist/united';

export const Container = styled(Flex)`
  align-items: center;
  height: 80px;
  justify-content: center;
  margin: 0 auto ${themeGet('olist.spacing.stack.small')} auto;
  max-width: 1328px;

  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.large}) {
          margin: auto ${themeGet('olist.spacing.stack.large')};
        }

        @media (max-width: ${theme.breakpoints.small}) {
          margin: auto ${themeGet('olist.spacing.stack.1xsmall')};

          button {
            margin-left: ${themeGet('olist.spacing.stack.1xsmall')} !important;
          }
        }
      `
    );
  }}
`;

export const BrandContainer = styled(Flex)`
  align-items: center;
  flex-wrap: wrap;
  gap: ${themeGet('olist.spacing.stack.1xsmall')} !important;
  justify-content: space-between;
  width: 100%;
`;

export const NavContainer = styled.nav`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
`;

export const DesktopText = styled.span`
  padding-left: 5px;
`;

export const MobileText = styled.span`
  display: none;
  padding-left: 5px;
`;

export const Text = styled(UnitedText)`
  align-items: center;
  display: flex;
`;

export const ActionsContainer = styled(Flex)`
  span {
    svg {
      margin-right: ${({ theme }) => theme.olist.spacing.stack['1xsmall']};
    }
  }

  a {
    color: ${themeGet('olist.color.neutral.stronger')};
    margin-right: ${themeGet('olist.spacing.stack.small')};
    &:hover {
      color: ${themeGet('olist.color.primary.base')};
      text-shadow: ${themeGet('olist.shadow.low')};
    }
  }

  button {
    margin-left: ${themeGet('olist.spacing.stack.small')};
  }

  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.small}) {
          ${DesktopText} {
            display: none;
          }
          ${MobileText} {
            display: initial;
          }
        }
      `
    );
  }}
`;

export const NavList = styled.ul`
  margin-left: ${themeGet('olist.spacing.stack.medium')};
  margin-top: ${({ theme }) => theme.olist.spacing.stack['1xsmall']};
`;

type StyledNavItemProps = {
  isSelect: boolean;
};

export const NavItem = styled.li<StyledNavItemProps>`
  align-items: center;
  border-bottom: ${({ isSelect }) => (isSelect ? '3px' : '0')} solid
    ${themeGet('olist.color.primary.base')};
  display: flex;
  justify-content: center;
  position: relative;

  img {
    margin-right: ${({ theme }) => theme.olist.spacing.stack['1xsmall']};
  }

  a {
    color: ${themeGet('olist.color.neutral.stronger')};
    font-size: ${({ theme }) => theme.olist.fontSize.medium};
    text-decoration: none;
  }

  &:hover {
    img {
      filter: brightness(0) saturate(100%) invert(29%) sepia(68%) saturate(2104%) hue-rotate(12deg)
        brightness(97%) contrast(95%);
    }

    a {
      color: ${themeGet('olist.color.primary.base')};
    }
  }
`;
