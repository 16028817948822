import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

import { Box } from '@olist/united';

export const InputContainer = styled(Box)`
  display: inline-block;
  width: 100%;
`;

export const InputHelperContainer = styled(Box)`
  margin-top: 8px;
`;

export const InputHelperText = styled.span`
  color: ${themeGet('olist.color.neutral.stronger')};
  display: block;
  font-size: ${({ theme }) => theme.olist.fontSize['1xmini']};
  font-weight: ${themeGet('olist.fontWeight.regular')};
  width: 100%;
`;

export const InputErrorText = styled.span`
  color: ${themeGet('olist.color.alert.base')};
  display: block;
  font-size: ${({ theme }) => theme.olist.fontSize['1xmini']};
  font-weight: ${themeGet('olist.fontWeight.regular')};
  width: 100%;
`;
