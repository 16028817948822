import { themeGet } from '@styled-system/theme-get';
import styled, { css } from 'styled-components';

import { Flex } from '@olist/united';

export const MainContainer = styled(Flex)`
  background-color: ${themeGet('olist.color.neutral.softest')};
  flex-direction: column;
  margin: 0 auto ${themeGet('olist.spacing.stack.small')} auto;
  max-width: 1328px;
  position: relative;

  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.large}) {
          margin: auto ${themeGet('olist.spacing.stack.large')};
        }

        @media (max-width: ${theme.breakpoints.small}) {
          margin: auto ${themeGet('olist.spacing.stack.1xsmall')};

          button {
            margin-left: ${themeGet('olist.spacing.stack.1xsmall')} !important;
          }
        }
      `
    );
  }}
`;
