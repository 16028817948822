import { useEffect, useMemo } from 'react';

import { get } from 'lodash';
import { useController } from 'react-hook-form';
import { TypographyProps } from 'styled-system';

import { Input, Select, Textarea } from '@olist/united';

import type { State } from '@partners/views/Opportunities/Create';

import * as S from './FormField.styles';

import type { UseControllerProps } from 'react-hook-form';

type FormType = 'input' | 'select' | 'select-filter' | 'textarea';
export type SelectOption = { value: string; label: string };

type FormFieldProps = {
  append?: string;
  autocomplete?: string;
  defaultValue?: string | string[];
  disabled?: boolean;
  helpText?: string;
  name: string;
  options?: SelectOption[];
  placeholder?: string;
  type?: FormType;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onError?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isMulti?: boolean;
} & UseControllerProps<Partial<State>>;

type InputElementProps = Partial<FormFieldProps> &
  Partial<HTMLInputElement> &
  Partial<TypographyProps> & { append?: string; error: string };

const FormField = (props: FormFieldProps) => {
  const { control, defaultValue, helpText, name, onChange, onError, options, type, ...inputProps } =
    props;

  const { field, formState } = useController({
    control,
    name,
    defaultValue: defaultValue || '',
  });
  const { onChange: fieldOnChange, ...fieldProps } = field;
  const { errors } = formState;

  const renderError = (error: string) => error && <S.InputErrorText>{error}</S.InputErrorText>;

  useEffect(() => {
    if (onError && get(errors, name))
      onError({
        target: { name, value: get(errors, name)?.message },
      } as React.ChangeEvent<HTMLInputElement>);
  }, [errors, name, onError]);

  const renderInputElement = (input: InputElementProps) => {
    if (type === 'select') {
      return (
        <Select title={`select ${name}`} defaultValue={defaultValue} {...input}>
          {options?.map((opt) => (
            <option key={opt.value} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </Select>
      );
    }

    if (type === 'textarea') {
      return <Textarea {...input} />;
    }

    return <Input {...input} />;
  };

  const inputElementProps = useMemo(
    () =>
      ({
        fontWeight: 'semibold',
        error: get(errors, name),
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
          onChange(event);
          fieldOnChange(event);
        },
        ...inputProps,
        ...fieldProps,
      } as InputElementProps),
    [errors, fieldOnChange, fieldProps, inputProps, name, onChange]
  );

  return (
    <S.InputContainer>
      {renderInputElement(inputElementProps)}
      <S.InputHelperContainer>
        {helpText && !get(errors, name) && <S.InputHelperText>{helpText}</S.InputHelperText>}
        {renderError(get(errors, name)?.message)}
      </S.InputHelperContainer>
    </S.InputContainer>
  );
};

FormField.defaultProps = {
  append: undefined,
  defaultValue: '',
  disabled: false,
  helpText: undefined,
  placeholder: undefined,
  options: [],
  type: 'input',
};

export { FormField };
