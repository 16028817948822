import NextLink from 'next/link';

import { Button } from '@olist/united';

import { Image } from '@common/components/Image';

import * as S from './Header.styles';

export type HeaderProps = {
  onLogoutClick: () => void;
  username?: string;
};

export const Header: React.FC<HeaderProps> = ({ onLogoutClick, username }) => {
  return (
    <header>
      <S.Container>
        <S.BrandContainer>
          <S.NavContainer>
            <NextLink href="/home" passHref>
              <a href="replace">
                <Image src="/images/vnda-logo-horizontal.svg" alt="logo da vnda" height="24px" />
              </a>
            </NextLink>
          </S.NavContainer>
          <S.ActionsContainer>
            <S.Text>
              <NextLink href="/docs">Documentações</NextLink>
            </S.Text>
            {username && <S.Text>{username}</S.Text>}
            <Button type="button" onClick={onLogoutClick}>
              Sair
            </Button>
          </S.ActionsContainer>
        </S.BrandContainer>
      </S.Container>
    </header>
  );
};
