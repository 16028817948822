import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';

import { Flex, Image, Text } from '@olist/united';

import { Link } from '@common/components';

export const Container = styled(Flex)`
  align-items: center;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  margin: ${themeGet('olist.spacing.stack.small')};
`;

export const NotFoundImage = styled(Image)`
  margin-bottom: ${themeGet('olist.spacing.stack.small')};
  max-width: calc(100vw - ${themeGet('olist.spacing.stack.small')} * 2);
`;

export const Tip = styled(Text)`
  color: ${themeGet('olist.color.neutral.stronger')};
  font-size: ${({ theme }) => theme.olist.fontSize['3xsmall']};
`;

export const Title = styled(Text.H2)``;

export const Subtitle = styled(Text)`
  text-align: center;
`;

export const BackToLink = styled(Link)`
  margin-top: ${themeGet('olist.spacing.stack.medium')};
`;
